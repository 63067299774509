.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .linkWrapper {
    text-align: center;
  }

  .link {
    text-decoration: none;
    cursor: pointer;
  }
}

@media screen and (min-width: 1024px) {
  .links {
    gap: 14px;
  }
}
