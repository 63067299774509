.container {
  max-width: 1650px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .imageWrapper {
    position: relative;
    width: 100%;
    min-height: 626px;
  }

  .textOverlay {
    position: absolute;
    top: 4%;

    .title {
      font-size: 24px;
      margin-bottom: 36px;
    }

    .subtitle {
      margin: 0 auto;
      padding-bottom: 42px;
      max-width: 245px;
      text-align: center;
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 100%;
    height: 100%;

    .textOverlay {
      position: absolute;
      top: 15%;

      .title {
        font-size: 48px;
        margin-bottom: 30px;
      }

      .subtitle {
        padding-bottom: 46px;
        max-width: 420px;
        text-align: center;
        font-size: 18px;
      }
    }
  }
}
